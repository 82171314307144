import * as React from 'react';

import customerPromise from '../../images/customer-promise.jpg';
import qualityGuarantee from '../../images/quality-guarantee.png';
import LiCheck from '../utils/li-check';

const AboutWhoAreWe = () => (
  <article className="flex flex-wrap max-w-6xl px-6 py-4 mx-auto mt-20">
    <div className="w-full md:w-1/3">
      <h1 className="text-5xl font-semibold leading-none uppercase heading font-display">
        <span className="inline-block">About </span>
        <br />
        <span className="inline-block">us</span>
      </h1>
      <div className="flex items-center -mx-2">
        <div className="w-16 mx-2 mt-4">
          <img
            src={qualityGuarantee}
            alt="quality guarantee icon logo"
            className="flex-shrink-0"
          />
        </div>
        <div className="w-16 mx-2 mt-4">
          <img
            src={customerPromise}
            alt="customer promise icon logo"
            className="flex-shrink-0"
          />
        </div>
      </div>
    </div>
    <div className="w-full mt-6 md:mt-0 md:w-2/3">
      <div className="rich-text">
        <h2>Frontline Removals: who are we?</h2>
        <p>
          Nestled conveniently in between Sydney and Brisbane, our head-office
          is located in the heart of New South Wales mid-North Coast. And our
          container transhipping yards? They’re wide-spread across a number of
          Australia locations — carefully selected for your convenience.
        </p>
        <p>What do we do? We’re removalist and storage specialists.</p>
        <h3>
          For more than {new Date().getFullYear() - 1995} years, we’ve taken the
          stress out of home and office relocations
        </h3>
        <p>
          In 1996, our founder, Jamie Baker, noticed a gap in the industry. A
          gap for streamlined removal services that eliminated double-handling
          and enhanced customer service.
        </p>
        <p>
          With a wealth of experience, a passion for meaningful relationships,
          and a knack for relocation efficiency, Jamie built Frontline Removals
          from the ground up. He instilled ingenuity and dedication every step
          of the way.
        </p>
        <h3>
          From 1996 to {new Date().getFullYear()}, we’ve stuck to our values —
          and it’s paid off
        </h3>
        <p>
          Today, we have the same mission: to streamline shipping services and
          take the headache out of relocations.
        </p>
        <p>
          We value customer service. Because of this passion we create
          long-lasting relationships — in fact, we’re still working with clients
          from way back!
        </p>
        <p>
          But don’t think we’re stuck in the past. We’re always looking ahead
          and embracing the future. We’re continuously evolving to meet our
          customers’ changing needs.
        </p>
        <h3>
          We’ve been around for years, so you don’t have to worry about a thing
        </h3>
        <ul>
          <LiCheck>Shipping queries? We’ve got the answers.</LiCheck>
          <LiCheck>Concerns? We’ve got the solutions.</LiCheck>
          <LiCheck>
            Seeking a trustworthy storage, shipping provider? We’re the guys for
            the job.
          </LiCheck>
        </ul>
        <br />
        <h2>
          Frontline Removals: Specialised moving services that allow you to
          store and ship your belongings in one smooth, streamlined process.
        </h2>
      </div>
    </div>
  </article>
);

export default AboutWhoAreWe;
