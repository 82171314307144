import { Link } from 'gatsby';
import * as React from 'react';

const AboutCTA = () => (
  <article className="w-full max-w-5xl mx-auto my-auto">
    <p className="text-6xl font-semibold leading-none uppercase font-display text-brand-blue">
      Dedicated <br />
      moving specialists <br />
      in the heart of <br />
      <span className="text-brand-orange">New South Wales</span>
    </p>
    <p>
      <Link
        to="/furniture-removal/#quote-form"
        offset="0"
        className="mt-4 button"
      >
        Get Quote
      </Link>
    </p>
  </article>
);

export default AboutCTA;
