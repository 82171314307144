import * as React from 'react';

import LiCheck from '../utils/li-check';

const AboutWhatMakesUsDifferent = () => (
  <article className="flex flex-wrap max-w-6xl px-6 py-4 mx-auto mt-20">
    <div className="w-full mt-6 ml-auto md:mt-0 md:w-2/3">
      <div className="rich-text">
        <h2>What makes us different from the rest?</h2>
        <p>
          When it comes to relocation services, we’re highly-regarded in our own
          personal niche — a removals bubble, if you will.
        </p>
        <h3>
          We bridge the industry gap of double-handling. How? With purpose-built
          shipping containers.
        </h3>
        <ul>
          <LiCheck>Relocating your items? We’ll pack up a container.</LiCheck>
          <LiCheck>
            Looking for storage? We’ll organise you a container.
          </LiCheck>
          <LiCheck>
            Wanting your own container to renovate? We’ll sell you one.
          </LiCheck>
        </ul>
        <h3>
          We’re different — no one ships the way we do. But we’re also
          experienced — you can’t make these things up!
        </h3>
        <br />
        <h2>The Frontline team</h2>
        <h3>(AKA, the dedicated people who move your belongings)</h3>
        <p>
          Here at Frontline, we’re a team of 20 — an intimate work family, who
          value good old fashioned hard work, customer service and
          collaboration!
        </p>
        <p>
          We’re a long-standing team. One employee has been with us for 20
          years, another 15. At Frontline, our rewarding work culture naturally
          creates rewarding relationships with our clients.
        </p>
        <h2>The values we implement every day</h2>
        <h3>At Frontline, helping people is what we do</h3>
        <ul>
          <LiCheck>We’re customer focused</LiCheck>
          <LiCheck>
            We uphold personal, meaningful customer conversations
          </LiCheck>
          <LiCheck>We instil honesty, trust and integrity in our work</LiCheck>
          <LiCheck>Our services are reliable and end-to-end</LiCheck>
        </ul>
      </div>
    </div>
  </article>
);

export default AboutWhatMakesUsDifferent;
