import * as React from 'react';

import { Hero, Layout, SEO } from '../components';
import Banner from '../components/about/about-banner';
import CTA from '../components/about/about-cta';
import HeroImage from '../components/about/about-hero-image';
import AboutWhatMakesUsDifferent from '../components/about/about-what-makes-us-different';
import WhoAreWe from '../components/about/about-who-are-we';

const AboutPage = () => (
  <Layout formName="about-page-bottom-form">
    <SEO
      title="About Us | Streamlined Shipping &amp; Storage | Frontline Removals"
      description="Frontline Removals: New South Wales trusted, experienced shipping and storage provider. We’ve been around for years. Get in touch with our expert team."
    />
    <Hero image={<HeroImage />} cta={<CTA />} />
    <WhoAreWe />
    <Banner />
    <AboutWhatMakesUsDifferent />
  </Layout>
);

export default AboutPage;
