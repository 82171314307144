import PropTypes from 'prop-types';
import * as React from 'react';
import { IoIosCheckmark } from 'react-icons/io';

const LiCheck = ({ children }) => (
  <li className="flex items-center">
    <IoIosCheckmark className="flex-shrink-0 text-4xl text-brand-orange" />
    <span className="">{children}</span>
  </li>
);

LiCheck.propTypes = {
  children: PropTypes.node,
};

export default LiCheck;
